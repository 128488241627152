.Notice {
    background: rgb(37, 37, 37);
    color: var(--body-background);
    display: flex;
    padding: 12px 20px 8px;
}

.Notice-text {
    --color: white;
    color: var(--color);
    border-bottom: 1px solid transparent;
    font-size: 14px;
    border-radius: 0;
    transition: all 0.3s ease;
}

.Notice-text:hover {
    border-bottom-color: var(--color);
}

@media (max-width: 1023px) {
    .Notice-text__full {
        display: none;
    }
}

@media(min-width: 1024px) {
    .Notice {
        justify-content: center;
    }
    .Notice-text__compact {
        display: none;
    }
}
