.Cart {
    background: #252525;
    color: #bcbbb4;
    padding: 40px;
    transition: transform 0.5s ease;
    position: fixed;
    width: 100%;
    top: 0;
    box-sizing: border-box;
    z-index: 100;
}

.Cart__hidden {
    transform: translateY(-100%);
}

.Cart-close {
    fill: #999;
    height: 14px;
    width: 14px;
    padding: 0;
    position: absolute;
    top: 40px;
    right: 40px;
}

.Cart-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.Cart-right > *:not(:last-child) {
    margin-bottom: 26px;
}

.Cart-information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 26px;
}

@media (min-width: 1025px) {
    .Cart-information {
        grid-template-columns: 2fr 1fr;
    }
}

.Cart-subtotal {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.Cart-subtotal__amount {
    font-size: 25px;
    text-align: right;
}
.Cart-subtotal__title {
    font-weight: bold;
}

.CartItems {
    width: 100%;
    font-size: 16px;
    border-collapse: inherit;
    border-spacing: 0;
}

.CartItems th {
    padding: 0 0 15px 0;
    font-size: 14px;
}

.CartItems td {
    padding: 15px 0;
}

.CartItem-title {
    font-weight: bold;
}
.CartItem-qty {
    width: calc(50% - 75px);
}

@media(min-width: 1025px) {
    .CartItem-qty {
        width: calc(33.33% - 75px);
    }
}

.CartItem-price {
    width: 75px;
}

.CartItem-remove {
    color: currentColor;
    appearance: none;
    opacity: 0;
    transition: opacity 0.3s ease;
    font-size: 1em;
}

.CartItem:hover .CartItem-remove {
    opacity: 1;
}

.CartItem-price {
    text-align: right;
}

.CartItems th {
    border-bottom: 2px solid rgba(255,254,242,.1);
    padding-bottom: 15px;
}

.CartItems thead {
    text-align: left;
}

.Cart-checkout-btn {
    width: 100%;
    background-color: #eaeadf;
    height: 60px;
    min-width: 210px;
    padding: 21px 23px;
    position: relative;
    transition: all .5s ease;
    font-size: 1em;
}

@media(max-width: 639px) {
    .CartItems,
    .CartItems tbody,
    .CartItems thead,
    .CartItems th,
    .CartItem,
    .CartItem td {
        display: grid;
    }

    .CartItem {
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }

    .CartItem-title {
        width: 100%;
    }

    .CartItem-remove {
        opacity: 1;
        padding: 0;
        border: 0;
        margin: 0;
    }

    .CartItem-qty select {
        position: absolute;
        top: 14px;
        right: 14px;
    }

    .CartItem-price {
        position: absolute;
        bottom: 14px;
        right: 14px;
    }

    .CartItems th:not(.CartItems-heading__cart) {
        display: none;
    }

    .Cart-information {
        display: block;
    }

    .Cart {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

}
