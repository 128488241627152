.ProductAttribute-heading {
    font-weight: bold;
    padding-top: 14px;
    margin-bottom: 11px;
    position: relative;
    padding-right: 20px;
}

.ProductAttribute-detail {
    padding-bottom: 14px;
    margin: 0;
    color: #666;
}

.ProductAttribute-detail:not(:last-child) {
    border-bottom: 1px solid #d5d4c9;
}

.ProductAttribute-action {
    position: absolute;
    right: 0;
    top: 14px;
    outline: none;
}

.ProductAttribute-action polygon {
    fill: #333;
    stroke: #333;
    stroke-width: 2px;
    transition: all 0.3s ease;
}

.ProductAttribute-action circle {
    stroke: #333;
    stroke-width: 3px;
    transition: all 0.3s ease;
}

.ProductAttribute-action:hover polygon {
    fill: var(--body-background);
    stroke: var(--body-background);
}

.ProductAttribute-action:hover circle {
    fill: #333;
}
