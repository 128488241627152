.Klarna {
    padding: 14px 0;
}

.Klarna a {
    color: currentColor;
}

.Klarna-brand {
    font-weight: bold;
}