.ProductDetail {
    padding-top: 120px;
}

.ProductDetail h1 {
    font-size: 30px;
}

.ProductDetail-attributes {
    border-top: 2px solid #4a4a4a;
    margin: 24px 0 0 0;
}

@media(max-width: 1024px) {
    .ProductDetail {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media(min-width: 1025px) {
    .ProductDetail {
        display: flex;
        box-sizing: border-box;
        width: 100%;
    }
    .ProductDetail-content {
        width: 50%;
    }
}

@media(min-width: 1025px) and (max-width: 1219px) {
    .ProductDetail {
        padding-right: 80px;
        padding-bottom: 80px;
        padding-left: 80px;
    }
}

@media(min-width: 1220px) { 
    .ProductDetail-content {
        width: calc(100% * 3 / 12);
        margin-right: calc(100% * 1 / 12);
    }
}
