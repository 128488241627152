.AddToCart-button {
    background: #333;
    color: var(--body-background);
    display: block;
    max-width: 470px;
    padding: 15px 20px;
    display: block;
    width: 100%;
    transition: background 0.3s ease;
    font-size: 14px;
}

.AddToCart-button:hover {
    background: black;
}
