.ProductOption {
    padding: 14px 0;
    border-top: 1px solid #d5d4c9;
}

.ProductOption-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.ProductOption-input {
    display: none;
}

.ProductOption-inputs {
    display: flex;
    flex-wrap: wrap;
}

.ProductOption-label {
    display: flex;
    margin: 4px 25px 4px 0;
    align-items: center;
    cursor: pointer;
}

.ProductOption-proxy-input {
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 50%;
    border: 1px solid #666;
    position: relative;
    margin-right: 5px;
}

.ProductOption-input + .ProductOption-proxy-input:after {
    content: '';
    background: #666;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.ProductOption-input:hover + .ProductOption-proxy-input:after {
    opacity: 0.7;
}

.ProductOption-input:checked + .ProductOption-proxy-input:after {
    opacity: 1;
}
