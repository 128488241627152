.ProductImages img {
    display: block;
    max-width: 100%;
    height: auto;   
    max-height: 300px;
    margin: auto;
}

@media(min-width: 1024px) {
    .ProductImage {
        width: 50%;
    }
}

@media(min-width: 1220px) {
    .ProductImage {
        width: calc(100% * 4 / 12);
        margin-left: auto;
        align-self: center;
    }

    .ProductImages img {
        max-height: 600px;
    }
}
