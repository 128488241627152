:root {
  --color: rgb(51, 51, 51);
  --body-background: rgb(255, 254, 243);
}

body {
  margin: 0;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--body-background);
  color: var(--color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 92%;
  max-width: 1600px;
  margin: auto;
}

button {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 0;
}
