.GlobalNavigation-desktop {
    justify-content: space-between;
    padding-right: 40px;
    padding-left: 40px;
}

.GlobalNavigation-desktop,
.GlobalNavigation-desktop ul {
    display: flex;
    align-items: center;
}

.GlobalNavigation-desktop ul {
    height: 80px;
}

.GlobalNavigation-desktop ul,
.GlobalNavigation-desktop li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.GlobalNavigation-desktop a,
.GlobalNavigation-desktop button {
    color: currentColor;
    text-decoration: none;
    font-weight: bold;
    display: block;
    margin-right: 1.3vw;
    font-size: 1em;
    padding: 0;
    outline: none;
}

@media(max-width: 1023px) {
    .GlobalNavigation__hidden_md {
        display: none;
    }
    .GlobalNavigation-desktop {
        border-bottom: 1px solid rgb(229, 229, 229);
    }
}

@media(min-width: 1024px) {
    .GlobalNavigation-shop-btn {
        display: none;
    }
}

@media (max-width: 639px) {
    .GlobalNavigation-left li:not(.GlobalNavigation-brand) {
        display: none;
    }
}

@media (min-width: 640px) {
    .GlobalNavigation-brand {
        position: absolute;
        top: calc(50px + 74px + 40px);
        left: 34px;
    }
}

@media (min-width: 1025px) {
    .GlobalNavigation-brand {
        top: calc(80px + 74px + 25px);
        left: 40px;
    }
    .GlobalNavigation-brand svg {
        height: 40px !important;
        width: 120px !important;
    }
}
